<template>
  <div id="container">
    <div></div>
  </div>
</template>
<script>
import AMapLoader from "@amap/amap-jsapi-loader";
import { stationDistribution } from "@/api/dataBoard.js";
import { ToDigital } from "@/utils/mapUtils";
export default {
  data() {
    return {
      adressList: [],
      imgUrl: require("../assets/icon/station.png"),
    };
  },
  methods: {
    initMap() {
      AMapLoader.load({
        key: "7aaeac218d34a1d903a125e022ea2c6f", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [""], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      })
        .then((AMap) => {
          this.map = new AMap.Map("container", {
            //设置地图容器id
            viewMode: "3D", //是否为3D地图模式
            zoom: 4, //初始化地图级别
            center: [105.602725, 37.076636], //初始化地图中心点位置
            mapStyle: "amap://styles/whitesmoke",
          });
          const icon = new AMap.Icon({
            size: new AMap.Size(20, 20), // 图标尺寸
            image: this.imgUrl, // Icon的图像
            imageSize: new AMap.Size(20, 20),
          });

          let makers = [];
          this.adressList.forEach((item) => {
            const marker = new AMap.Marker({
              position: new AMap.LngLat(item.lng, item.lat),
              offset: new AMap.Pixel(-10, -10),
              icon: icon, // 添加 Icon 实例
              // title: "北京",
              // zoom: 13,
            });
            const infowindow = new AMap.InfoWindow({
              offset: new AMap.Pixel(0, -10),
            });
            makers.push(marker);
            marker.on("mouseover", (e) => {
              infowindow.setContent(`
                <div class="info-box">
                  <div class='info-name'>${item.stationName}</div>
                </div>
              `);
              infowindow.open(this.map, new AMap.LngLat(item.lng, item.lat));
            });
            marker.on("mouseout", () => {
              infowindow.close(this.map, new AMap.LngLat(item.lng, item.lat));
            });
          });
          console.log(makers, "-----makers----");
          // 将 Icon 实例添加到 marker 上:
          // const marker = new AMap.Marker({
          //   position: new AMap.LngLat(116.405467, 39.907761),
          //   offset: new AMap.Pixel(-10, -10),
          //   icon: icon, // 添加 Icon 实例
          //   title: "北京",
          //   // zoom: 13,
          // });
          this.map.add(makers);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    // 电站分布
    getStationDistribution() {
      stationDistribution({}).then((res) => {
        this.adressList = res.data.filter((item) => {
          if (item.latitude && item.longitude) {
            item.lat = ToDigital(...item.latitude.split(","));
            item.lng = ToDigital(...item.longitude.split(","));
          }
          return item.latitude && item.longitude;
        });
        this.initMap();
        console.log(res, "-------------------------", this.adressList);
      });
    },
  },
  mounted() {
    this.getStationDistribution(); // 电站分布
  },
};
</script>
<style lang="less" scoped>
#container {
  padding: 0px;
  margin: 0px;
  width: 100%;
  height: 100%;
}
.info-box {
  padding: 10px;
  // border-radius: 50%;
}
/deep/.info-name {
  font-size: 18px;
  color: #000;
}
/deep/.amap-info-close {
  display: none;
}
</style>