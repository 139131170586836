<template>
  <div class="max-box">
    <div class="box-title">数据看板</div>
    <div class="all-grid">
      <a-row :gutter="12">
        <!-- 整体发电概况 -->
        <a-col :span="12">
          <div class="gutter-box">
            <div class="row-title">
              <div class="left-title">整体发电概况</div>
              <div class="right-box">
                数据更新于：{{ moment().format("YYYY-MM-DD HH:mm:ss") }}
                UTC+08:00
              </div>
            </div>
            <div class="data-echarts">
              <div class="echarts-left">
                <div ref="waterpolo" id="waterpolo"></div>
              </div>
              <div class="echarts-right">
                <div class="center-box">
                  <div class="total-power">
                    <div class="total-title">当前发电总功率</div>
                    <div class="total-num">
                      <span>{{ (powerResult.allActivePower / 1000) }}</span> kW
                    </div>
                  </div>
                  <div class="right-line"></div>
                  <div class="total-power">
                    <div>总装机容量</div>
                    <div class="total-num">
                      <span>{{ Number(powerResult.allCapacity).toFixed(2) }}</span> KWp
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row-line"></div>
            <div class="statistics-box">
              <div
                class="one-box"
                v-for="item in powerProfile"
                :style="getColor(item.color)"
              >
                <!-- :style="getStyle()" -->
                <div>{{ item.title }}</div>
                <div class="statistics-num">
                  <span>{{ item.num }} </span>{{ item.unit }}
                </div>
              </div>
            </div>
          </div>
        </a-col>
        <!-- 整体发电历史 -->
        <a-col :span="12">
          <div class="gutter-box flex-column">
            <div class="row-title">
              <div class="left-title">整体发电历史</div>
              <div class="date-check">
                <div class="y-m-box">
                  <div
                    :class="currentTime == -1 ? 'chooseTime' : ''"
                    @click="changeYM(-1, 'currentTime')"
                  >
                    月
                  </div>
                  <div
                    :class="currentTime == 1 ? 'chooseTime' : ''"
                    @click="changeYM(1, 'currentTime')"
                  >
                    年
                  </div>
                </div>
                <div class="time-picker">
                  <a-icon type="left" @click="dealYM(-1, 'powerTime')" />
                  <a-month-picker
                    :allowClear="false"
                    class="picker"
                    v-model="powerTime"
                    v-if="currentTime == -1"
                  />
                  <a-date-picker
                    class="picker"
                    :allowClear="false"
                    v-model="powerTime"
                    format="YYYY"
                    mode="year"
                    :open="open"
                    @openChange="openChange"
                    @panelChange="panelChange"
                    v-else
                  />
                  <a-icon type="right" @click="dealYM(1, 'powerTime')" />
                </div>
              </div>
            </div>
            <div class="current-total">
              <div>
                <span
                  >当{{ this.currentTime == -1 ? "月" : "年" }}发电量：</span
                >
                <span><!--{{ allHistoryPower }} KWh--> </span> <!--暂时不用，后续更改-->

                <span v-for="item in powerProfile" v-if="currentTime == -1">
                  {{item.prop=='monthPower'?item.num+'KWh':''}}
                </span>
                <span v-for="item in powerProfile" v-if="currentTime == 1">
                  {{item.prop=='yearPower'?item.num+'KWh':''}}
                </span>

              </div>
            </div>
            <div class="echarts-box">
              <div id="powerHistory"></div>
            </div>
          </div>
        </a-col>
      </a-row>
      <a-row :gutter="12" class="mt-16">
        <!-- 电站状态简报 -->
        <a-col :span="12">
          <div class="gutter-box">
            <div class="row-title">
              <div class="left-title">电站状态简报</div>
            </div>
            <div class="station-status-box">
              <div class="power-station">
                <div class="station-icon">
                  <svg
                    class="svg-icon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                  >
                    <g data-name="图层 2">
                      <g data-name="图层 1">
                        <path
                          data-name="Clip 2"
                          d="M0 0h24v24H0z"
                          fill="none"
                        />
                        <path
                          d="M16.28 7.93a.75.75 0 01.73.65l2 11.61a.71.71 0 01-.16.63.69.69 0 01-.57.28h-16a.72.72 0 01-.57-.28.78.78 0 01-.16-.63l2-11.61a.75.75 0 01.73-.65zm-.88 1.55H5a.74.74 0 00-.72.64L3 18.64a.81.81 0 00.16.64.73.73 0 00.57.27h13a.74.74 0 00.57-.27.81.81 0 00.16-.64l-1.32-8.52a.78.78 0 00-.77-.64zM8.76 14.9a.76.76 0 01.74.77V18a.82.82 0 01-.22.55.71.71 0 01-.52.22H5.22a.69.69 0 01-.57-.28.8.8 0 01-.16-.65l.45-2.32a.73.73 0 01.72-.62zm6.06 0a.73.73 0 01.72.62l.46 2.32a.84.84 0 01-.16.65.72.72 0 01-.57.28h-3.55a.73.73 0 01-.52-.22.82.82 0 01-.2-.55v-2.33a.76.76 0 01.74-.77zm-6.06-4.65a.76.76 0 01.74.75v2.32a.82.82 0 01-.22.55.72.72 0 01-.52.23H6a.7.7 0 01-.57-.29.75.75 0 01-.16-.64l.45-2.33a.75.75 0 01.72-.62zm5.32 0a.75.75 0 01.72.62l.45 2.33a.82.82 0 01-.16.64.73.73 0 01-.57.29h-2.8a.72.72 0 01-.52-.23.8.8 0 01-.22-.55V11a.76.76 0 01.74-.78h2.36zM19.82 12l.88.91a.4.4 0 010 .56.38.38 0 01-.54 0l-.89-.92a.39.39 0 01.55-.55zM17.9 6.28a3.24 3.24 0 010 5.55l-.6-3.58a.71.71 0 00-.62-.59h-3.19a3.2 3.2 0 011.26-1.39 3.12 3.12 0 013.15.01zm3 2.35h1.26a.4.4 0 01.38.4.39.39 0 01-.39.39h-1.3a.39.39 0 01-.38-.4.4.4 0 01.38-.39zm-.3-3.92a.43.43 0 010 .56l-.89.91a.39.39 0 01-.55 0 .43.43 0 010-.56l.89-.91a.38.38 0 01.54 0zm-8.08-.11l.89.91a.4.4 0 010 .56.38.38 0 01-.54 0L12 5.16a.4.4 0 010-.56.39.39 0 01.54 0zm3.8-1.7a.4.4 0 01.39.39v1.29a.41.41 0 01-.39.4.41.41 0 01-.39-.4V3.3a.38.38 0 01.38-.4z"
                        />
                      </g>
                    </g>
                  </svg>
                </div>
                <div class="station-total">
                  电站总数 <span>{{ totalStation }}</span>
                </div>
              </div>
              <div class="station-four">
                <div v-for="item in powerStation" class="one-status">
                  <div class="status-title">{{ item.title }}</div>
                  <div class="status-num">{{ item.value }}</div>
                </div>
              </div>
            </div>
          </div>
        </a-col>
        <!-- 电站分布 -->
        <a-col :span="12">
          <div class="gutter-box position-box">
            <div class="row-title position-title">
              <div class="left-title">电站分布</div>
            </div>
            <div class="map-box">
              <map-container></map-container>
            </div>
          </div>
        </a-col>
      </a-row>
      <a-row :gutter="12" class="mt-16">
        <!-- 功率归一化排名 -->
        <a-col :span="12">
          <div class="gutter-box flex-column">
            <div class="row-title">
              <div class="left-title">功率归一化排名</div>
            </div>
            <div class="rank-box">
              <div class="one-rank" v-for="i in dashboardPowerData">
                <div class="station-name">{{ i.stationName }}</div>
                <div class="progress-box">
                  <a-progress :percent="+i.avgActivePower" status="active" />
                </div>
                <div class="follow-box" title="关注">
                  <!-- <a-rate v-model="value" :count="1" class="star-style" /> -->
                </div>
              </div>
            </div>
          </div>
        </a-col>
        <!-- 日满发小时排名 -->
        <a-col :span="12">
          <div class="gutter-box flex-column">
            <div class="row-title">
              <div class="left-title">等效小时数排名</div>
              <div class="right-rank">
                <div class="time-picker">
                  <a-icon type="left" @click="dealDay(-1)" />
                  <a-date-picker
                    v-model="hourTime"
                    :allowClear="false"
                    class="picker"
                  />
                  <a-icon type="right" @click="dealDay(1)" />
                </div>
                <!-- <div class="right-arrow-box">
                  <a-icon type="arrow-right" class="arrow-right-icon" />
                </div> -->
              </div>
            </div>
            <div class="hour-table">
              <a-table
                :columns="hourColumns"
                :data-source="hourData"
                class="table-y"
                :pagination="false"
                :scroll="{ y: 130 }"
              >
                <div slot="action"></div>
              </a-table>
              <!-- <a-table :columns="hourColumns"></a-table> -->
            </div>
          </div>
        </a-col>
      </a-row>

      <a-row :gutter="12" class="mt-16">
        <!-- 已完成工单偏差排名 -->
        <a-col :span="12">
          <div class="gutter-box flex-column">
            <div class="row-title">
              <div class="left-title">已完成工单偏差排名</div>
              <div class="right-rank">
                <!-- <div class="right-arrow-box">
                  <a-icon type="arrow-right" class="arrow-right-icon" />
                </div> -->
              </div>
            </div>
            <div class="work-order-table">
              <a-table
                :columns="workOrderColumns"
                :data-source="workOrderData"
                class="table-y"
                :scroll="{ y: 130 }"
                :pagination="false"
              >
                <div slot="action"></div>
              </a-table>
            </div>
          </div>
        </a-col>
        <!-- 新增工单趋势 -->
        <a-col :span="12">
          <div class="gutter-box flex-column">
            <div class="row-title">
              <div class="left-title">新增工单趋势</div>
              <!-- <div class="date-check">
                <div class="y-m-box">
                  <div
                    :class="workOrderFlag == -1 ? 'chooseTime' : ''"
                    @click="changeYM(-1, 'workOrderFlag')"
                  >
                    月
                  </div>
                  <div
                    :class="workOrderFlag == 1 ? 'chooseTime' : ''"
                    @click="changeYM(1, 'workOrderFlag')"
                  >
                    年
                  </div>
                </div>
                <div class="time-picker">
                  <a-icon type="left" @click="dealYM(-1, 'orderTime')" />
                  <a-month-picker
                    :allowClear="false"
                    class="picker"
                    v-model="orderTime"
                    v-if="workOrderFlag == -1"
                  />
                  <a-date-picker
                    class="picker"
                    :allowClear="false"
                    v-model="orderTime"
                    format="YYYY"
                    mode="year"
                    :open="orderOpen"
                    @openChange="openOrder"
                    @panelChange="panelOrder"
                    v-else
                  />
                  <a-icon type="right" @click="dealYM(1, 'orderTime')" />
                </div>
              </div> -->
            </div>
            <div class="current-total">
              <div>
                <span>当月新增工单：</span>
                <span>{{ monthOrder }}</span>
              </div>
            </div>
            <div class="echarts-box">
              <div id="orderTrend"></div>
            </div>
          </div>
        </a-col>
      </a-row>
      <a-row class="mt-16">
        <div class="workorder-person flex-column">
          <div class="row-title">
            <div class="left-title">工单处理人员排名</div>
            <!-- <div class="right-rank">
              <a-dropdown>
                <a-menu slot="overlay">
                  <a-menu-item
                    :key="item.value"
                    v-for="item in orderWorkStatusList"
                    @click="handleMenuClick(item, 'orderWorkStatus')"
                  >
                    {{ item.label }}
                  </a-menu-item>
                </a-menu>

                <a-button class="btn-value">
                  {{ orderPersonText }} <a-icon type="down" />
                </a-button>
              </a-dropdown>
            </div> -->
          </div>
          <div class="workorder-table">
            <a-table
              :columns="orderPersonColumns"
              :data-source="orderPersonData"
              class="table-y"
              :scroll="{ y: 170 }"
              :pagination="false"
            >
              <template #status>
                <span class="status-style">工作状态</span>

                <a-popover placement="bottom">
                  <template slot="content">
                    <div class="question-cnt" style="color">
                      <div>空闲：该人员无"执行中"工单</div>
                      <div>工作中：该人员有"执行中"工单</div>
                    </div>
                  </template>
                  <a-icon type="question-circle" class="ques-icon" />
                </a-popover>
              </template>
              <template #statusSlot="text"> {{ getStatus(text) }}</template>
            </a-table>
            <Pagination
              :page.sync="pagination.page"
              :pageSize.sync="pagination.pageSize"
              :total.sync="pagination.total"
              @paginationChange="getDashboardHandlerRanking"
              class="page-box"
            />
          </div>
        </div>
      </a-row>

      <a-row :gutter="12" class="mt-16">
        <!-- 工单状态简报 -->
        <a-col :span="12">
          <div class="gutter-box flex-column">
            <div class="row-title">
              <div class="left-title">工单状态简报</div>
              <div class="right-rank">
                <!-- <div class="right-arrow-box">
                  <a-icon type="arrow-right" class="arrow-right-icon" />
                </div> -->
              </div>
            </div>
            <div class="status-report">
              <div class="total-box">
                总计：<span>{{ allWorkStatus }}</span>
              </div>
              <div class="report-box">
                <div
                  class="one-report"
                  v-for="item in reportStatusList"
                  @click="toWorkPage(item)"
                >
                  <div class="report-num">{{ item.value }}</div>
                  <div class="report-txt-box">
                    <div
                      class="circle"
                      :style="getBackground(item.color)"
                    ></div>
                    <div class="report-status">{{ item.status }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </a-col>
        <!-- 未完成工单超时排名 -->
        <a-col :span="12">
          <div class="gutter-box flex-column">
            <div class="row-title">
              <div class="left-title">未完成工单超时排名</div>
              <div class="right-rank">
                <!-- <div class="right-arrow-box">
                  <a-icon type="arrow-right" class="arrow-right-icon" />
                </div> -->
              </div>
            </div>
            <div class="work-order-table">
              <a-table
                :columns="uncompleteworkOrderColumns"
                :data-source="uncompletedOrderData"
                class="table-y"
                :scroll="{ y: 130 }"
                :pagination="{
                  pageSizeOptions: ['50', '100'],
                  showQuickJumper: true,
                }"
              >
                <div slot="action"></div>
              </a-table>
            </div>
          </div>
        </a-col>
      </a-row>
      <!--  <a-row :gutter="12" class="mt-16">
        <!~~ 整体计划发电量 ~~>
        <a-col :span="12">
          <div class="gutter-box flex-column">
            <div class="row-title">
              <div class="left-title">整体计划发电量</div>
              <div class="date-check">
                <div class="y-m-box">
                  <div
                    :class="allPlanFlag == -1 ? 'chooseTime' : ''"
                    @click="changeYM(-1, 'allPlanFlag')"
                  >
                    年
                  </div>
                  <div
                    :class="allPlanFlag == 1 ? 'chooseTime' : ''"
                    @click="changeYM(1, 'allPlanFlag')"
                  >
                    总
                  </div>
                </div>
                <div class="time-picker">
                  <a-icon type="left" @click="dealYear(-1)" />
                  <a-date-picker
                    class="picker"
                    :allowClear="false"
                    v-model="allPlanTime"
                    format="YYYY"
                    mode="year"
                    :open="allPlanOpen"
                    @openChange="openOrder"
                    @panelChange="panelOrder"
                    v-if="allPlanFlag == -1"
                  />
                  <a-range-picker
                    v-else
                    class="rang-picker"
                    v-model="allTime"
                    format="YYYY"
                    disabled
                  />
                  <a-icon type="right" @click="dealYear(1)" />
                </div>
              </div>
            </div>
            <div class="echarts-box">
              <div id="planpower"></div>
            </div>
          </div>
        </a-col>
      </a-row>-->
    </div>
  </div>
</template>
<script>
import moment from "moment";
import MapContainer from "../components/Mapcontainer.vue";
import {
  powerHistoryEchart,
  powerSurveyEchart,
  orderTrendEchart,
  planPowerEchart,
} from "./homeData/index";
import {
  powerOverview,
  allPowerHistory,
  stationStatus,
  dashboardPower,
  dashboardFullHour,
  dashboardTaskDeviation,
  dashboardTaskStatus,
  dashboardTaskIncomplete,
  dashboardHandlerRanking,
  dashboardAddTaskTrend,
} from "@/api/dataBoard.js";
export default {
  data() {
    return {
      //发电概况数据
      powerProfile: [
        {
          title: "当日发电量",
          num: "0",
          unit: "kWh",
          color: "rgb(245, 251, 255)",
          prop: "dayPower",
        },
        {
          title: "当月发电量",
          num: "0",
          unit: "kWh",
          color: "rgb(255, 248, 247)",
          prop: "monthPower",
        },
        {
          title: "当年发电量",
          num: "0",
          unit: "kWh",
          color: "rgb(246, 245, 255)",
          prop: "yearPower",
        },
        {
          title: "累计发电量",
          num: "0",
          unit: "kWh",
          color: "rgb(245, 250, 246)",
          prop: "allPower",
        },
      ],
      powerResult: {},

      option: {
        series: [
          {
            type: "liquidFill",
            radius: "78.1%",
            center: ["50%", "50%"],
            color: ["#ecf3fe", "#c8dcfe", "#5594fa"],
            data: [0.7522, 0.7522, 0.7522], // data个数代表波浪数
            amplitude: 5,
            // 图形样式
            itemStyle: {
              opacity: 1, // 波浪的透明度
              shadowBlur: 0, // 波浪的阴影范围
            },
            backgroundStyle: {
              borderWidth: 2,
              borderColor: "#709DFA",
              color: "#fff",
            },
            label: {
              show: true,
              textStyle: {
                color: "#515561",
                insideColor: "#515561",
                fontSize: 15,
              },
              formatter: (params) => {
                return `${(params.value * 100).toFixed(2)}%`;
              },
            },
            outline: {
              show: false,
            },
          },
        ],
      },
      allHistoryPower: 0,
      //整体发电时间
      currentTime: -1,
      powerTime: moment(),
      open: false,
      // 电站状态简报
      powerStation: [
        {
          title: "接入中电站",
          value: "1",
          prop: "join",
        },
        {
          title: "全部设备离线电站",
          value: "4",
          prop: "allOffLineCount",
        },
        {
          title: "部分设备离线电站",
          value: "0",
          prop: "partOffLineCount",
        },
        {
          title: "有报警电站",
          value: "0",
          prop: "",
        },
      ],
      totalStation: 0,
      // 功率归一化排名
      dashboardPowerData: [],
      // 名次
      rankList: [
        {
          value: "1",
          label: "后十名",
        },
        {
          value: "2",
          label: "前十名",
        },
      ],
      powerRank: "1",
      value: 0,
      // 日满发小时排名
      hourRank: "1",
      hourTime: moment(),
      hourColumns: [
        {
          title: "电站名称",
            width: 300,
          dataIndex: "stationName",
        },
        {
          title: "地址",
            width: 300,
          dataIndex: "address",
        },
        {
          title: "等效小时数(h)",
          dataIndex: "fullHour",
        },
        {
          scopedSlots: { customRender: "action" },
        },
      ],
      hourData: [],

      // 已完成工单偏差排名
      workOrderColumns: [
        {
          title: "工单标题",
          dataIndex: "",
          ellipsis: true,
        },
        {
          title: "处理人",
          dataIndex: "",
        },
        {
          title: "计划用时(h)",
          dataIndex: "",
        },
        {
          title: "实际用时(h)",
          dataIndex: "",
        },
        {
          title: "偏差(%)",
          dataIndex: "sums",
          sortDirections: ["ascend", "descend"],
          sorter: (a, b) => a.sums - b.sums,
        },
      ],
      workOrderData: [],

      // 新增工单趋势
      workOrderFlag: -1,
      orderTime: moment(),
      orderOpen: false,

      //工单处理人员排名
      orderWorkStatusList: [
        {
          value: "1",
          label: "全部",
        },
        {
          value: "2",
          label: "空闲",
        },
        {
          value: "3",
          label: "工作中",
        },
      ],
      orderWorkStatus: "1",
      orderPersonColumns: [
        {
          title: "姓名",
          dataIndex: "handleName",
        },
        {
          dataIndex: "taskStatus",
          scopedSlots: {
            customRender: "statusSlot",
          },
          slots: {
            title: "status",
          },
        },
        {
          title: "总体评价",
          sortDirections: ["ascend", "descend"],
          sorter: (a, b) => a.sums - b.sums,
          dataIndex: "overallScore",
        },
        {
          title: "响应",
          sortDirections: ["ascend", "descend"],
          sorter: (a, b) => a.sums - b.sums,
          dataIndex: "responseScore",
        },
        {
          title: "质量",
          sortDirections: ["ascend", "descend"],
          sorter: (a, b) => a.sums - b.sums,
          dataIndex: "qualityScore",
        },
        {
          title: "效率",
          sortDirections: ["ascend", "descend"],
          sorter: (a, b) => a.sums - b.sums,
          dataIndex: "efficiencyScore",
        },
        {
          title: "已完成工单数量",
          sortDirections: ["ascend", "descend"],
          sorter: (a, b) => a.sums - b.sums,
        },
      ],
      orderPersonData: [
        {
          status: "11111111",
        },
      ],
      statusListDetail: [
        {
          value: "1",
          label: "待接收",
        },
        {
          value: "2",
          label: "拒绝接受",
        },
        {
          value: "3",
          label: "执行中",
        },
        {
          value: "4",
          label: "待验证",
        },
        {
          value: "5",
          label: "待评价",
        },
        {
          value: "6",
          label: "已完成",
        },
      ],
      //工单状态简报
      reportStatusList: [
        {
          value: "0",
          status: "待接受",
          color: "#f8b51e",
          prop: "accepted",
          workstatus: "1",
        },
        {
          value: "0",
          status: "拒绝接受",
          color: "#f8b51e",
          prop: "refuse",
          workstatus: "2",
        },
        // {
        //   value: "0",
        //   status: "待处理",
        //   color: "#f8b51e",
        //   prop: "",
        // },
        {
          value: "0",
          status: "执行中",
          color: "#048fff",
          prop: "execute",
          workstatus: "3",
        },
        {
          value: "0",
          status: "待验证",
          color: "#f8b51e",
          prop: "verified",
          workstatus: "4",
        },
        {
          value: "0",
          status: "待评价",
          color: "#f8b51e",
          prop: "evaluated",
          workstatus: "5",
        },
        {
          value: "0",
          status: "已完成",
          color: "#41d068",
          prop: "completed",
          workstatus: "6",
        },
        // {
        //   value: "0",
        //   status: "已撤销",
        //   color: "#8c8c8c",
        //   prop: "",
        // },
      ],
      allWorkStatus: 0,

      // 未完成工单超时排名
      uncompletedOrderData: [],
      uncompleteworkOrderColumns: [
        {
          title: "工单标题",
          dataIndex: "",
          ellipsis: true,
        },
        {
          title: "处理人",
          dataIndex: "",
        },
        {
          title: "状态",
          dataIndex: "",
        },

        {
          title: "超时时长",
          dataIndex: "sums",
          sortDirections: ["ascend", "descend"],
          sorter: (a, b) => a.sums - b.sums,
        },
      ],
      //整体计划发电量
      allPlanFlag: -1,
      allPlanTime: moment(),
      allTime: [moment("2015"), moment("2023")],
      allPlanOpen: false,
      moment,
      pagination: {
        page: 1,
        pageSize: 50,
        total: 0,
      },
      monthOrder: 0, //当月新增工单
    };
  },
  components: { MapContainer },
  methods: {
    // 获取颜色
    getColor(color) {
      return {
        background: color,
      };
    },
    toWorkPage(item) {
      this.$router.push({
        path: "/operationMaintenance/workOrder",
        query: {
          status: item.workstatus,
        },
      });
    },
    // 切换年月
    changeYM(index, variable) {
      if (index == this[variable]) return;
      this[variable] = index;
      if (variable == "currentTime") {
        this.getAllPowerHistory();
      }
    },
    // 年份选择
    openChange(val) {
      if (val) {
        this.open = true;
      } else {
        this.open = false;
      }
    },
    panelChange(val) {
      this.powerTime = moment(val);
      this.open = false;
    },
    dealYM(num, variable) {
      let time = moment(this[variable]);
      if (this.currentTime == -1) {
        this[variable] = num < 0 ? time.subtract(1, "M") : time.add(1, "M");
      } else {
        this[variable] = num < 0 ? time.subtract(1, "y") : time.add(1, "y");
      }
      if (variable == "powerTime") {
        this.getAllPowerHistory();
      }
    },

    //日满发小时排名
    handleMenuClick(item, variable) {
      this[variable] = item.value;
    },
    dealDay(num) {
      let time = moment(this.hourTime);
      this.hourTime = num < 0 ? time.subtract(1, "d") : time.add(1, "d");
      this.getDashboardFullHour();
    },

    //新增工单趋势
    openOrder(val) {
      if (val) {
        this.orderOpen = true;
      } else {
        this.orderOpen = false;
      }
    },
    panelOrder(val) {
      this.orderTime = moment(val);
      this.orderOpen = false;
    },

    getBackground(color) {
      return {
        background: color,
      };
    },
    initEcharts(data) {
      //整体发电情况
      //整体发电历史
      //新增工单趋势
      const orderTrend = document.getElementById("orderTrend", null, {
        devicePixelRatio: window.devicePixelRatio,
      });
      const orderTrendVm = this.echarts.init(orderTrend);
      orderTrendVm.setOption(orderTrendEchart(data));
    },

    // 整体发电概况
    getPowerOverview() {
      powerOverview({})
        .then((res) => {
        	console.log(res.data)
          this.powerResult = res.data;
          this.powerProfile.forEach((item) => {
            for (let k in res.data) {
              if (k == item.prop) {
                item.num = Number(res.data[k]).toFixed(2);
                console.log(k+'：'+item.num)
              }
            }
          });
          const waterPolo = document.getElementById("waterpolo");
          this.$nextTick(() => {
            const waterPoloEchart = this.echarts.init(waterPolo, null, {
              devicePixelRatio: window.devicePixelRatio,
            });
            waterPoloEchart.setOption(powerSurveyEchart((res.data.allActivePower / 1000) / res.data.allCapacity));
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 整体发电历史
    getAllPowerHistory() {
      const powerHistory = document.getElementById("powerHistory", null, {
        devicePixelRatio: window.devicePixelRatio,
      });
      const powerHistoryVm = this.echarts.init(powerHistory);

      let startTime =
        this.currentTime == -1
          ? moment(this.powerTime).startOf("month")
          : moment(this.powerTime).startOf("year");
      let endTime =
        this.currentTime == -1
          ? moment(this.powerTime).endOf("month")
          : moment(this.powerTime).endOf("year");
      let requestData = {
        timeType: this.currentTime == -1 ? 1 : 2,
        startTime: startTime.format("YYYY-MM-DD 00:00:00"),
        endTime: endTime.format("YYYY-MM-DD 00:00:00"),
      };

      allPowerHistory(requestData).then((res) => {
        let xData = [],
          yData = [];
        res.data.map((item) => {
          xData.push(...Object.keys(item));
          yData.push(...Object.values(item));
        });
        let unit = "kWh";
        let legend = this.currentTime == -1 ? "当日发电量" : "当月发电量";
        this.allHistoryPower = yData
          .reduce((total, c) => {
            return (total += c);
          }, 0)
          .toFixed(2);
        powerHistoryVm.setOption(
          powerHistoryEchart({ xData, yData }, unit, legend)
        );
      });
    },

    // 电站状态简报
    getStationStatus() {
      stationStatus({}).then((res) => {
        this.powerStation.forEach((item) => {
          for (let k in res.data) {
            if (k == item.prop) {
              item.value = res.data[k];
            }
          }
        });
        this.totalStation = res.data.count;
      });
    },
    // 功率归一化排名
    getDashboardPower() {
      dashboardPower({
        createTime: moment().format("YYYY-MM-DD 00:00:00"),
      }).then((res) => {
        res.data.forEach((item) => {
          item.avgActivePower = (item.avgActivePower * 100).toFixed(2);
        });
        this.dashboardPowerData = res.data;
        console.log(this.dashboardPowerData)
      });
    },

    // 日满发小时排名
    getDashboardFullHour() {
      dashboardFullHour({
        createTime: moment(this.hourTime).format("YYYY-MM-DD 00:00:00"),
      }).then((res) => {
        this.hourData = res.data;
      });
    },

    //已完成工单偏差排名
    getDashboardTaskDeviation() {
    	console.log('已完成工单偏差排名待完成')
      // dashboardTaskDeviation({ taskStatus: "6" }).then((res) => {
      //   console.log(res);
      //   this.workOrderData = res.data;
      // });
    },

    //工单状态简报
    getDashboardTaskStatus() {
	    console.log('工单状态简报待完成')
      // dashboardTaskStatus({}).then((res) => {
      //   console.log(res);
      //   this.allWorkStatus = res.data.all;
      //   this.reportStatusList.forEach((item) => {
      //     for (let k in res.data) {
      //       if (k == item.prop) {
      //         item.value = res.data[k];
      //       }
      //     }
      //   });
      // });
    },

    //未完成工单超时排名
    getDashboardTaskIncomplete() {
	    console.log('未完成工单超时排名待完成')
      // dashboardTaskIncomplete({ timeoutStatus: 1 }).then((res) => {
      //   console.log(res);
      //   this.uncompletedOrderData = res.data;
      // });
    },

    // 工单处理人排名
    getDashboardHandlerRanking(pageMsg) {
      let requestPage = {
        page: 1,
        limit: 50,
      };
      if (pageMsg?.page) {
        requestPage.page = pageMsg.page;
        requestPage.limit = pageMsg.pageSize;
      }
	    console.log('工单处理人排名待完成')
      // dashboardHandlerRanking(requestPage).then((res) => {
      //   this.orderPersonData = res.data;
      //   this.pagination.total = res.count;
      // });
    },
    getStatus(text) {
      let find = this.statusListDetail.find((item) => {
        return item.value == text;
      });
      if (find) {
        return find.label;
      } else {
        return "--";
      }
    },

    // 获取新增工单
    getDashboardAddTaskTrend() {
    	console.log('获取新增工单待完成')
      // dashboardAddTaskTrend().then((res) => {
      //   let echartsData = res.data.map((item) => {
      //     return [item.createTime, item.taskNumberGroup];
      //   });
	  //
      //   this.monthOrder = res.data.reduce((total, c) => {
      //     return (total += +c.taskNumberGroup);
      //   }, 0);
      //   this.initEcharts(echartsData);
      //   console.log(res, "------当月新增工单");
      // });
    },
  },
  computed: {
    powerRankText() {
      let value = this.rankList.find((item) => {
        return item.value == this.powerRank;
      });
      if (value) {
        return value.label;
      }
    },
    hourRankText() {
      let value = this.rankList.find((item) => {
        return item.value == this.hourRank;
      });
      if (value) {
        return value.label;
      }
    },
    orderPersonText() {
      let value = this.orderWorkStatusList.find((item) => {
        return item.value == this.orderWorkStatus;
      });
      if (value) {
        return value.label;
      }
    },
  },
  mounted() {
    this.getAllPowerHistory(); // 整体发电历史
    this.getPowerOverview(); // 整体发电概况
    this.getStationStatus(); // 电站状态简报

    this.getDashboardPower(); // 功率归一化排名
    this.getDashboardFullHour(); // 日满发小时排名
    this.getDashboardTaskDeviation(); //已完成工单偏差排名
    this.getDashboardTaskStatus(); //工单状态简报
    this.getDashboardTaskIncomplete(); //未完成工单超时排名

    this.getDashboardHandlerRanking(); //工单处理人排名

    this.getDashboardAddTaskTrend();
  },
};
</script>
<style lang="less" scoped>
.max-box {
  width: 100%;
  height: 100%;
  padding-top: 16px;
  padding-left: 20px;
  padding-bottom: 20px;
  box-sizing: border-box;
  overflow-y: auto;
  .box-title {
    font-size: 18px;
    // padding-left: 20px;
    color: #252b3a;
    margin-bottom: 8px;
  }
  .all-grid {
    padding: 8px 20px 0 0;
    // padding-top: 8px;
    box-sizing: border-box;
    .gutter-box {
      height: 320px;
      background: #fff;
      padding: 16px 0;
      box-sizing: border-box;
      .row-title {
        display: flex;
        padding: 0 16px;
        justify-content: space-between;
        font-size: 14px;
        margin-bottom: 16px;
        .left-title {
          color: #252b3a;
        }
        .right-box {
          color: #95929c;
          font-size: 12px;
        }
        .date-check {
          display: flex;
          align-items: center;
          box-sizing: border-box;
          .y-m-box {
            display: flex;
            height: 24px;
            font-size: 12px;
            border: 1px solid #d9d9d9;
            box-sizing: border-box;
            div {
              width: 30px;
              height: 24px;
              // line-height: px;
              cursor: pointer;
              text-align: center;
              &:hover {
                border-color: #048fff;
                color: #048fff;
                background: #048fff1a;
              }
            }
            .chooseTime {
              border-color: #048fff;
              color: #048fff;
              background: #048fff1a;
            }
          }
          .time-picker {
            margin-left: 8px;
            cursor: pointer;
            &:hover {
              color: #048fff;
            }
            .picker {
              margin: 0 6px;
            }
            .ant-calendar-picker {
              /deep/.ant-calendar-picker-input {
                width: 128px;
                height: 30px;
                font-size: 12px;
              }
              // height: 24px;
            }
            .rang-picker {
              margin: 0 6px;

              /deep/.ant-calendar-picker-input {
                width: 144px;
                height: 30px;
                font-size: 12px;
              }
              // height: 24px;
            }
          }
        }
        .right-rank {
          display: flex;
          align-items: center;
          .btn-value {
            height: 24px;
            width: 88px;
            font-size: 12px;
            padding: 0 10px;
            color: #00000073;
          }
          .right-arrow-box {
            width: 24px;
            height: 24px;
            background: #f8f8f8;
            border-radius: 2px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 10px;

            cursor: pointer;
            &:hover {
              color: #048fff;
            }
          }
          .time-picker {
            margin-left: 8px;
            cursor: pointer;
            &:hover {
              color: #048fff;
            }
            .picker {
              margin: 0 6px;
            }
            .ant-calendar-picker {
              /deep/.ant-calendar-picker-input {
                width: 128px;
                height: 30px;
                font-size: 12px;
              }
              // height: 24px;
            }
          }
        }
      }
      // 整体发电历史
      .current-total {
        width: 100%;
        height: 32px;
        color: #515561;
        font-size: 12px;
        margin-top: 12px;
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .echarts-box {
        flex: 1;
        padding: 0 16px;
        box-sizing: border-box;
        div {
          width: 100%;
          height: 100%;
        }
      }
      .data-echarts {
        width: 100%;
        height: 152px;
        display: flex;
        .echarts-left {
          flex: 6;
          display: flex;
          justify-content: center;
          div {
            width: 160px;
            height: 100%;
          }
        }
        .echarts-right {
          flex: 4;
          display: flex;
          flex-direction: column;
          justify-content: center;
          .center-box {
            .total-power {
              color: #95929c;
              font-size: 12px;
              .total-title {
                margin-bottom: 4px;
              }
              .total-num {
                span {
                  font-size: 20px;
                  color: #252b3a;
                }
              }
            }
            .right-line {
              width: 136px;
              margin-top: 4px;
              margin-bottom: 8px;
              border-bottom: 1px solid #e3e3e7;
            }
          }
        }
      }
      .row-line {
        width: 100%;
        height: 8px;
        border-bottom: 1px solid #e3e3e7;
        margin-bottom: 12px;
      }
      .statistics-box {
        // padding: 0 16px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        .one-box {
          flex: 1;
          margin: 0 8px;
          // width: 165px;
          padding: 20px 8px 0 8px;
          box-sizing: border-box;
          font-size: 12px;
          color: #515561;
          .statistics-num {
            margin: 4px 0;
            span {
              font-size: 16px;
              color: #515561;
            }
          }
        }
      }

      // 电站状态简报
      .station-status-box {
        padding: 0 16px;
        box-sizing: border-box;
        margin-top: 16px;
        .power-station {
          display: flex;
          align-items: center;
          .station-icon {
            width: 48px;
            height: 48px;
            border-radius: 50%;
            background: #048fff1a;
            margin-right: 8px;
            padding: 9px;
            box-sizing: border-box;
            .svg-icon {
              width: 100%;
              height: 100%;
              fill: #048fff;
            }
          }
          .station-total {
            height: 30px;
            color: #92959c;
            font-size: 12px;
            span {
              color: #252b3a;
              font-size: 20px;
            }
          }
        }
        .station-four {
          margin-top: 12px;
          display: grid;
          grid-template-rows: 80px 80px;
          grid-template-columns: 1fr 1fr;
          grid-column-gap: 20px;
          grid-row-gap: 12px;

          .one-status {
            padding: 16px 0 0 16px;
            background: #f8f8f9;
            box-sizing: border-box;
            .status-title {
              color: #92959c;
              font-size: 12px;
              margin-bottom: 8px;
            }
            .status-num {
              color: #252b3a;
              font-size: 16px;
            }
          }
        }
      }

      // 功率归一化排名
      .rank-box {
        width: 100%;
        // height: 100%;
        padding: 0 16px;
        box-sizing: border-box;
        // background: #f60;
        margin-top: 16px;
        // flex-grow: 1;
        overflow-y: auto;
        .one-rank {
          height: 16px;
          width: 100%;
          display: flex;
          align-items: center;
          margin-bottom: 16px;
          .station-name {
            font-size: 12px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 50%;
          }
          .progress-box {
            width: 70%;
          }
          .follow-box {
            font-size: 16px;
            display: flex;
            align-items: center;
            // background: #bfa;

            margin-left: 16px;
            .star-style {
              font-size: 16px;
              color: #fb9678;
            }
          }
        }
      }

      //日满发小时排名
      .hour-table {
        width: 100%;
        flex-grow: 1;
        // height: 100%;
        height: 226px;
        padding: 0 16px;
        // overflow-y: auto;
        box-sizing: border-box;
        margin-top: 16px;
        overflow: hidden;
        // background: #bfa;

        .table-y {
          /deep/.ant-table-header {
            font-size: 12px;
            background: #fff;
            color: #252b3a;
          }
          /deep/.ant-table-thead {
            font-size: 12px;
            background: #fff;
            color: #252b3a;
          }
          /deep/.ant-table-thead > tr > th {
            background: #fff;
          }
          /deep/.ant-table-row td {
            height: 45px;
            padding: 10px;
          }
        }
      }

      //已完成工单偏差排名
      .work-order-table {
        overflow: hidden;
        padding: 0 16px;
        box-sizing: border-box;
        flex: 1;
        /deep/.ant-table-header,
        .ant-table-thead {
          font-size: 12px;
          background: #fff;
          color: #252b3a;
        }
        // background: #bfa;
      }

      // 工单状态简报
      .status-report {
        padding: 0 16px;
        box-sizing: border-box;
        flex: 1;
        .total-box {
          font-size: 12px;
          color: #95929c;
          margin-bottom: 4px;
          span {
            font-size: 16px;
            color: #252b3a;
          }
        }
        .report-box {
          display: grid;
          width: 100%;
          margin-top: 25px;
          grid-template-rows: 70px 70px 70px;
          grid-template-columns: 1fr 1fr 1fr;
          grid-column-gap: 16px;
          grid-row-gap: 16px;
          .one-report {
            padding: 12px 0;
            box-sizing: border-box;
            background: #fafafd;
            cursor: pointer;
            .report-num {
              color: #515561;
              font-size: 16px;
              margin-left: 26px;
            }
            .report-txt-box {
              display: flex;
              margin-left: 16px;
              align-items: center;
              .circle {
                width: 6px;
                height: 6px;
                border-radius: 50%;
              }
              .report-status {
                color: #515561;
                font-size: 12px;
                margin-left: 4px;
              }
            }
          }
        }
      }
    }
    .workorder-person {
      height: 386px;
      background: #fff;
      padding: 16px;
      box-sizing: border-box;
      .row-title {
        display: flex;
        padding: 0 16px;
        justify-content: space-between;
        font-size: 14px;
        margin-bottom: 16px;
        .left-title {
          color: #252b3a;
        }
        .right-rank {
          display: flex;
          align-items: center;
          .btn-value {
            height: 24px;
            width: 88px;
            font-size: 12px;
            padding: 0 10px;
            color: #00000073;
          }
        }
      }
      .workorder-table {
        /deep/.ant-table-header,
        .ant-table-thead {
          font-size: 12px;
          background: #fff;
          color: #252b3a;
        }
        .status-style {
        }
      }
      .ques-icon {
        font-size: 16px;
        margin-left: 5px;
        cursor: pointer;
        &:hover {
          color: #048fff;
        }
      }
    }
    .position-box {
      position: relative;
      .map-box {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
      }
      .position-title {
        position: absolute;
        z-index: 2;
        left: 16px;
        top: 16px;
      }
    }
    .flex-column {
      display: flex;
      flex-direction: column;
    }
  }
  .page-box {
    margin-top: 10px;
  }
}
.question-cnt {
  color: #252b3a;
  font-size: 12px;
}
/deep/
  .ant-table-thead
  > tr
  > th
  .ant-table-column-sorter
  .ant-table-column-sorter-inner-full {
  margin-top: -12px;
}
</style>
